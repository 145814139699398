import {applicationDuck} from '@features/Application/Application.ducks';
import {Middleware} from 'redux';
import get from 'lodash/get';

/* Utils */
import {logger} from '@utils/logger';
import unWrapError, {tryToDeriveStringError} from '@utils/request/unWrapError';

/* Types */
import {DisplayErrorEnum} from '@features/Jobs/jobs.types';

export enum FailureState {
  MISSING_FIELDS = 'MISSING_FIELDS',
  SERVICE_NOT_FLAGGED = 'SERVICE_NOT_FLAGGED',
  UNAUTHORIZED_ACTION = 'UNAUTHORIZED_ACTION',
  INVALID_ROLE = 'INVALID_ROLE',
  TECH_LEAD_REMOVAL = 'TECH_LEAD_REMOVAL',
  JOB_ALREADY_OPEN = 'JOB_ALREADY_OPEN',
  SERVICE_ALREADY_OPEN = 'SERVICE_ALREADY_OPEN',
  JOB_ALREADY_CLOSED = 'JOB_ALREADY_CLOSED',
  INVALID_EMAIL = 'INVALID_EMAIL',
  INVALID_PASSWORD = 'INVALID_PASSWORD',
  MISSING_PASSWORD = 'MISSING_PASSWORD',
  MISSING_EMAIL = 'MISSING_EMAIL',
  REQUEST_FAILED = 'REQUEST_FAILED',
  REJECTED = 'REJECTED',
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM',
}

export const ERROR_MESSAGES = {
  [FailureState.MISSING_FIELDS]: 'Please complete all required fields.',
  [FailureState.SERVICE_NOT_FLAGGED]: 'This service is not currently flagged.',
  [FailureState.UNAUTHORIZED_ACTION]: "You don't have permission to perform this action.",
  [FailureState.INVALID_ROLE]: 'Your account does not have permission to access the MDU Field Service App.',
  [FailureState.TECH_LEAD_REMOVAL]: 'The team lead cannot be removed from the project.',
  [FailureState.JOB_ALREADY_OPEN]: 'This job is already open.',
  [FailureState.JOB_ALREADY_CLOSED]: "This job is closed and can't be modified.",
  [FailureState.SERVICE_ALREADY_OPEN]: 'This service is already open.',
  [FailureState.INVALID_EMAIL]: 'This email address is not associated with an account.',
  [FailureState.INVALID_PASSWORD]: 'The password you entered is incorrect.',
  [FailureState.DEFAULT]: 'Something went wrong. Please refresh and try again.',
};

export const FAILURE_CONFIG: {[key: string]: {errorMessage: string; matcher: RegExp | null; status: number | null}} = {
  [FailureState.MISSING_FIELDS]: {errorMessage: ERROR_MESSAGES[FailureState.MISSING_FIELDS], matcher: /can't be blank/i, status: null},
  [FailureState.SERVICE_NOT_FLAGGED]: {errorMessage: ERROR_MESSAGES[FailureState.SERVICE_NOT_FLAGGED], matcher: /this service has no active flags/i, status: null},
  [FailureState.INVALID_ROLE]: {errorMessage: ERROR_MESSAGES[FailureState.INVALID_ROLE], matcher: /You are not logged in as a tech/i, status: null},
  [FailureState.UNAUTHORIZED_ACTION]: {errorMessage: ERROR_MESSAGES[FailureState.UNAUTHORIZED_ACTION], matcher: null, status: 401},
  [FailureState.TECH_LEAD_REMOVAL]: {errorMessage: ERROR_MESSAGES[FailureState.TECH_LEAD_REMOVAL], matcher: /cannot be removed from the project because it is the lead tech/i, status: null},
  [FailureState.JOB_ALREADY_OPEN]: {errorMessage: ERROR_MESSAGES[FailureState.JOB_ALREADY_OPEN], matcher: /you can only reopen a cancelled, unserviceable or completed unit/i, status: null},
  [FailureState.JOB_ALREADY_CLOSED]: {errorMessage: ERROR_MESSAGES[FailureState.JOB_ALREADY_CLOSED], matcher: /you can only revert services of open jobs/i, status: null},
  [FailureState.SERVICE_ALREADY_OPEN]: {errorMessage: ERROR_MESSAGES[FailureState.SERVICE_ALREADY_OPEN], matcher: /you can only revert a completed service/i, status: null},
  [FailureState.INVALID_EMAIL]: {errorMessage: ERROR_MESSAGES[FailureState.INVALID_EMAIL], matcher: /This email address is not associated with an account/i, status: null},
  [FailureState.INVALID_PASSWORD]: {errorMessage: ERROR_MESSAGES[FailureState.INVALID_PASSWORD], matcher: /The password you entered is incorrect/i, status: null},
  [FailureState.MISSING_PASSWORD]: {errorMessage: ERROR_MESSAGES[FailureState.MISSING_FIELDS], matcher: /Password is required/i, status: null},
  [FailureState.MISSING_EMAIL]: {errorMessage: ERROR_MESSAGES[FailureState.MISSING_FIELDS], matcher: /Email is required/i, status: null},
  [FailureState.REQUEST_FAILED]: {errorMessage: ERROR_MESSAGES[FailureState.DEFAULT], matcher: /request failed with status code/i, status: null},
  [FailureState.REJECTED]: {errorMessage: ERROR_MESSAGES[FailureState.DEFAULT], matcher: /rejected/i, status: null},
};

export const getFailureMessage = ({error = '', status}: {error: string; status?: number}) => {
  const foundMatch =
    Object.entries(FAILURE_CONFIG).find(([, {matcher, status: _status}]) => {
      if (matcher) return error.match(matcher);
      return status === _status;
    }) || tryToDeriveStringError(error);
  const [, {errorMessage}] = foundMatch;
  return errorMessage;
};

/**
 * Transform certain error messages from api responses into user friendly messages. Techs have complained that it's difficult to
 * understand what they mean.
 *
 * The transformed message, if dispatched via `notificationApiError`, will be displayed as a top banner via `components/Notification`.
 * See `pages/_app` for how the notification is triggered.
 */
const notificationMiddleware: Middleware = api => next => action => {
  if (action?.type?.endsWith('/rejected')) {
    if (get(action, 'meta.displayErrorType', '') === DisplayErrorEnum.inline) return next(action);
    const {payload = {}, error = {}} = action;

    /*
      Lets create a better flow of informational errors
      These are the ways I've seen ways to get at an error.
    */
    const primaryError: string = payload?.data?.errors?.[0] ?? unWrapError(payload?.data);
    const secondaryError: string = error.message || payload?.err;
    const firstErrorFound = [primaryError, secondaryError].find(Boolean) || '';
    const statusCodeAsString = [payload.statusText, payload.status].join(' : ');

    const errorMessageDetailed = `${action?.type || ''} ${primaryError} ${secondaryError} (${statusCodeAsString})`;

    logger(action?.type)(errorMessageDetailed);
    api.dispatch(applicationDuck.actions.notificationApiError({source: getFailureMessage({error: firstErrorFound, status: payload.status})}));
  }

  if (action?.type?.endsWith('/pending')) {
    api.dispatch(applicationDuck.actions.notificationApiPending());
  }
  return next(action);
};

export default notificationMiddleware;
