import React from 'react';
import {HTToastContainer} from 'ht-styleguide';
import {autoCloseToast} from '@constants/constants.base';

/**
 * One of two global notifications containers. This is a wrapper for Styleguide's `HTToastContainer`.
 * To display a toast notification, use Styleguide's `htToast` function.
 * Toasts generated from this container should contain concise messages, ex. "Project Approved"
 */
const ToastContainer = () => <HTToastContainer autoClose={autoCloseToast} pauseOnFocusLoss data-testid="notification_error" />;

export default ToastContainer;
