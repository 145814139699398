import {useEffect} from 'react';
import {AppProps} from 'next/app';
import {wrapper} from '@store/store';
import {useRouter} from 'next/router';
import {useDispatch, useSelector} from 'react-redux';

/* Integrations */
import {queryClient, QueryClientProvider, HydrationBoundary} from '@integrations/tanstackquery';
import MduBugsnag from '@integrations/bugsnag';

/* Global: scss */
import '@styles/globals.scss';
import '@public/fonts/fonts.css';

/* ducks */
import {applicationDuck} from '@features/Application/Application.ducks';

/* components */
import Notification, {notify} from '@components/Notification';
import ToastContainer from '@components/ToastContainer';
import {LoaderPage} from 'ht-styleguide';

/* Types */
import {NotifyType} from '@features/Application/application.types';

/* Constants */
import {DEFAULT_NOTIFICATION_AUTO_CLOSE_TIME} from '@components/Notification/notification.constants';

export interface IMyAppProps extends AppProps {
  Component: any;
  dehydratedState: any;
  pageProps: any;
}

const ErrorBoundary = MduBugsnag();
const MyApp = ({Component, pageProps}: IMyAppProps) => {
  const {type, message, id = '', autoClose = DEFAULT_NOTIFICATION_AUTO_CLOSE_TIME} = useSelector(applicationDuck.selectors.notificationSelector);
  const {loading} = useSelector(applicationDuck.selectors.applicationSelector);
  const dispatch = useDispatch();
  const router = useRouter();
  /**
   * This is the global notification. On close it will clear it.
   * We only allow 1 Notification at once.
   */
  useEffect(() => {
    if (type && message) {
      const t = type as NotifyType;
      if (!notify.isActive(id)) {
        notify[t](message, {
          toastId: id,
          onClose: () => dispatch(applicationDuck.actions.removeNotificationError()),
          autoClose,
        });
      }
    }
  }, [autoClose, dispatch, id, message, type]);

  const handleModalBodyInteraction = (url: string) => {
    if (url.includes('mdl')) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  };

  useEffect(() => {
    router.events.on('routeChangeComplete', handleModalBodyInteraction);
    return () => {
      router.events.off('routeChangeComplete', handleModalBodyInteraction);
    };
  }, [router.events]);

  return (
    <ErrorBoundary FallbackComponent={() => <div>Error Page</div>}>
      <QueryClientProvider client={queryClient}>
        {/* dehydrated state allows us to get any ssr/ssg data we want from a prebuilt page if needed */}
        <HydrationBoundary state={pageProps.dehydratedState}>
          <div className="appContainer">{Component.Layout ? Component.Layout(<Component {...pageProps} />) : <Component {...pageProps} />}</div>
          <Notification />
          <ToastContainer />
          {loading && <LoaderPage className="page_loader" />}
        </HydrationBoundary>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default wrapper.withRedux(MyApp);
