import {useRef, useMemo, useState, useEffect} from 'react';
import {useRouter} from 'next/router';
import {useAppDispatch, useAppSelector} from '@store/store';
import {jobsDuck} from '@features/Jobs/Jobs.ducks';
import {UnitWithServices} from '@features/Jobs/jobs.types';
import {projectsDuck} from '@features/Projects';
import {servicesDuck} from '@features/Services/Services.ducks';
import {ServiceDetails} from '@features/Services/services.types';
import {getServiceChecklistCount} from '@features/Services/services.utils';
import {skusDuck} from '@features/Skus';
import {QuestionsAPIByQuestion, SiteDataQuestion} from '@features/Skus/skus.types';
import {extractSiteDataAnswers} from '@features/Skus/skus.utils';

type TUseFetchData = {
  isChecklistEnabled: boolean | null;
  currentService: ServiceDetails | null;
  unit: UnitWithServices | null;
  skuId: number | null;
};

export const useFetchData = ({isChecklistEnabled, currentService, unit, skuId}: TUseFetchData) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const {pid, sid} = router.query;
  const orderToken = unit?.token;

  /* Ref */
  const getCurrentServiceIdRef = useRef<number>(0);

  /* Fetch Project data */
  useEffect(() => {
    if (router.isReady && pid) {
      const fetchProjectDeatils = async () => {
        await dispatch(projectsDuck.actions.getProjectById());
      };
      fetchProjectDeatils();
    }
  }, [router.isReady, dispatch, pid]);

  /* Fetch Job/Unit data */
  useEffect(() => {
    if (router.isReady) {
      const fetchJobDetails = async () => {
        await dispatch(jobsDuck.actions.getJobDetails());
      };
      if (!unit) fetchJobDetails();
    }

    return () => {
      /* Clear Service only if the incoming route is not the same as the current saved route data */
      if (Number(getCurrentServiceIdRef.current) !== Number(sid)) {
        dispatch(servicesDuck.actions.clearService());
      }
    };
  }, [router.isReady, dispatch, unit, sid]);

  /* Fetch Service data */
  useEffect(() => {
    if (router.isReady) {
      const fetchServiceData = async () => {
        await dispatch(servicesDuck.actions.getServiceDetails({sid}));
      };
      fetchServiceData();
    }
    return () => {
      /* Clear Service */
      dispatch(servicesDuck.actions.clearService());
    };
  }, [router.isReady, dispatch, sid]);

  /* Fetch Sku and Question Data */
  useEffect(() => {
    if (skuId) {
      dispatch(skusDuck.actions.getSkuById({skuId}));
    }
  }, [skuId, dispatch]);

  /* Fetch Service Checklist */
  useEffect(() => {
    if (isChecklistEnabled && orderToken && sid) {
      dispatch(servicesDuck.actions.getServiceChecklist({orderToken, serviceId: sid as string}));
    }
  }, [isChecklistEnabled, orderToken, sid, dispatch]);

  /* We do this to always have a reference to the currentService */
  useEffect(() => {
    getCurrentServiceIdRef.current = currentService?.id || 0;
  }, [currentService]);
};

export const formatSiteDataQA = ({skuSiteDataQuestions, answers}: {skuSiteDataQuestions: SiteDataQuestion[]; answers: {}}) =>
  (skuSiteDataQuestions || []).map(
    q =>
      ({
        id: q.id,
        answer: answers[q.id]?.text || '',
        input_type: q.input_type,
        text: q.text_direct,
      } as QuestionsAPIByQuestion)
  );

export type ServiceSiteDataBlockProps = {
  skuId: number;
  service?: ServiceDetails;
  isServiceCompleted?: boolean;
};

export const useSiteDataAnsweredQuestions = ({service, skuId, isServiceCompleted = false}: ServiceSiteDataBlockProps) => {
  const questionEntities = useAppSelector(skusDuck.selectors.getQuestionsEntities);
  const skuSiteDataQuestions: SiteDataQuestion[] = questionEntities[String(skuId)]?.siteDataQuestions;
  const selectedSku = useAppSelector(skusDuck.selectors.getSelectedSku);

  const siteDataAnswers = extractSiteDataAnswers(service);

  const answers = isServiceCompleted ? siteDataAnswers : selectedSku.siteDataQuestions;

  return formatSiteDataQA({skuSiteDataQuestions, answers});
};

export const useGetTabProps = ({isChecklistEnabled}: {isChecklistEnabled: boolean | null}) => {
  type TTabKeys = 'tasks' | 'details';
  const [activeTab, setActiveTab] = useState<TTabKeys>('details');

  const serviceChecklist = useAppSelector(servicesDuck.selectors.getServiceChecklist);

  // For race conditions on `isChecklistEnabled`
  useEffect(() => {
    if (isChecklistEnabled) {
      setActiveTab('tasks');
    }
  }, [isChecklistEnabled]);

  return useMemo(() => {
    const taskCount = getServiceChecklistCount(serviceChecklist);
    const tasksTabCopy = taskCount ? `Tasks (${taskCount.completed}/${taskCount.total})` : 'Tasks';

    const tabs = [
      {text: tasksTabCopy, key: 'tasks', isActive: activeTab === 'tasks', onClick: () => setActiveTab('tasks')},
      {text: 'Details', key: 'details', isActive: activeTab === 'details', onClick: () => setActiveTab('details')},
    ];
    return {tabs, activeTab};
  }, [activeTab, serviceChecklist]);
};
