import {createSlice, createAsyncThunk, createSelector} from '@reduxjs/toolkit';
import Bugsnag from '@bugsnag/js';
import Router from 'next/router';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

/* Apis */
import userAPIs from '@features/User/user.apis';

/* Utils */
import {authToken as AUTH_STORAGE} from '@utils/auth';

/* Types */
import {RootState} from '@store/store';
import {ErrorResponse} from '@features/Application/application.types';
import * as TS from '@features/User/user.types';

/* Constants */
import {TIMEZONES} from '@constants/constants.base';
import {FormattedDateByUserTimezoneInputs} from '@features/User/user.types';

/*
 *******************************************************
    INITIAL STATE
 *******************************************************
 */
const initialState = null as TS.IUserState | null;

/*
 *******************************************************
    ASYNC ACTIONS
 *******************************************************
 */
const asyncActions = {
  signIn: createAsyncThunk<TS.UserResponsePayload, {email: string; password: string}, {rejectValue: ErrorResponse}>('technician/signIn', async ({email, password}, {rejectWithValue}) => {
    const authResponse = await userAPIs.signIn(null, {email, password}, {});
    const {
      data: {auth_token: authToken},
    } = authResponse;

    if (authResponse.err) {
      return rejectWithValue(authResponse as ErrorResponse);
    }

    await AUTH_STORAGE.setAuthToken(authToken);

    const userResponse = await userAPIs.getUserInfo();
    const {id, firstName = '', lastName = ''} = userResponse?.data?.user || {};

    Bugsnag.setUser(id.toString(), email, `${firstName} ${lastName}`);

    return userResponse.err ? rejectWithValue(userResponse as ErrorResponse) : userResponse;
  }),
  getUserData: createAsyncThunk<TS.UserResponsePayload, void, {rejectValue: ErrorResponse}>('user/getUserData', async (_, {rejectWithValue}) => {
    const Response = await userAPIs.getUserInfo();

    if (Response.err) {
      return rejectWithValue(Response as ErrorResponse);
    }

    return Response;
  }),
  signOut: createAsyncThunk<null, void, {state: RootState; rejectValue: ErrorResponse}>('user/signOut', async (_, {rejectWithValue}) => {
    const userResponse = await userAPIs.signOut();

    if (userResponse.err) {
      return rejectWithValue(userResponse as ErrorResponse);
    }

    AUTH_STORAGE.removeAuthToken();
    Bugsnag.setUser(undefined, undefined, undefined);
    window.location.href = '/';

    return null;
  }),
  resetPassword: createAsyncThunk<null, {email: string}, {state: RootState; rejectValue: ErrorResponse}>('user/resetPassword', async ({email}, {rejectWithValue}) => {
    const userResponse = await userAPIs.resetPassword({email});

    if (userResponse.err) {
      return rejectWithValue(userResponse as ErrorResponse);
    }

    Router.replace('/');

    return null;
  }),
};

/*
 *******************************************************
    SLICE
 *******************************************************
 */
const {actions, reducer} = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(asyncActions.signOut.fulfilled, () => null)
      .addMatcher(
        action => [asyncActions.signIn.fulfilled, asyncActions.getUserData.fulfilled].some(actionCreator => actionCreator.match(action)),
        (_, action) => action.payload.data.user
      );
  },
});

/*
*******************************************************
  SELECTORS & SELECTOR METHODS
*******************************************************
*/
export const getUserState = (state: RootState) => state?.user ?? initialState;

/*
*******************************************************
  EXPORTS
*******************************************************
*/
dayjs.extend(utc);
dayjs.extend(timezone);

export const getFormattedDateByUserTimezone = ({date, format}: FormattedDateByUserTimezoneInputs) =>
  createSelector(getUserState, user =>
    dayjs
      .utc(date || Date.now())
      .tz(user?.timezone || TIMEZONES.AMERICA.LOS_ANGELES)
      .format(format)
  );

export const selectors = {
  getIsUserLoggedIn: createSelector(getUserState, user => user?.id && AUTH_STORAGE.isValidJWT()),
  getUserState: createSelector(getUserState, user => user),
  getUserStateByKey: (key: keyof TS.IUserState) => createSelector(getUserState, user => user && user[key]),
  getFormattedDateByUserTimezone,
};

const userActions = {...actions, ...asyncActions};

export const userDuck = {
  actions: userActions,
  reducer,
  selectors,
};
