import React from 'react';
import {ToastContainer, CloseButtonProps} from 'react-toastify';
import {Icon} from 'ht-styleguide';
import {DEFAULT_NOTIFICATION_AUTO_CLOSE_TIME} from './notification.constants';
import styles from './notification.module.scss';

const NotificationCloseIcon = ({closeToast}: CloseButtonProps) => <Icon name="v2-close-icon" className={styles.toastCloseButton} onClick={closeToast} />;

/**
 * One of two global notifications containers. This is used to display friendly error messages that
 * are derived from api responses. See `store/middleware/notifications` for more information.
 * - nudge
 */
const Notification = () => (
  <ToastContainer
    data-testid="notification_error"
    limit={1}
    position="top-center"
    autoClose={DEFAULT_NOTIFICATION_AUTO_CLOSE_TIME}
    closeOnClick
    pauseOnFocusLoss
    draggable
    pauseOnHover
    toastClassName={styles.toastWrapper}
    closeButton={NotificationCloseIcon}
  />
);

export default Notification;
