import Bugsnag, {OnErrorCallback} from '@bugsnag/js';
import isString from 'lodash/isString';

const log = (...args: Array<string>) => window.console.log(...args);
const info = (...args: Array<string>) => window.console.info(...args);

export type LoggerErrorType = Error | string;

export const logger =
  (source = '') =>
  (error: Error | string, onErrorCallback?: OnErrorCallback) => {
    let err;

    switch (true) {
      case error instanceof Error:
        err = error;
        break;
      case isString(error):
        err = new Error(`${source}: ${error}`);
        break;
      default:
        err = new Error(`${source}: ${JSON.stringify(error)}`);
    }

    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    if (Bugsnag?._client) {
      Bugsnag?.notify(err, onErrorCallback);
    }
  };

export default {log, info, logger};
