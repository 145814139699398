import {createSelector, createSlice} from '@reduxjs/toolkit';
import {AppState} from '@features/Application/application.types';
import {DEFAULT_NOTIFICATION_AUTO_CLOSE_TIME} from '@components/Notification/notification.constants';
import {RootState} from '@store/store';

/**
 *
 */
const initialState: AppState = {
  loading: false,
  notification: {
    type: '',
    message: '',
    level: 'page',
    id: '',
    autoClose: DEFAULT_NOTIFICATION_AUTO_CLOSE_TIME,
  },
};

const {actions, reducer} = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    removeNotificationError: state => {
      state.notification = {
        ...state.notification,
        type: '',
        message: '',
        level: '',
        id: '',
        autoClose: DEFAULT_NOTIFICATION_AUTO_CLOSE_TIME,
      };
    },
    notificationApiPending: state => {
      state.notification = {
        ...state.notification,
        type: '',
        message: '',
        level: 'page',
        autoClose: DEFAULT_NOTIFICATION_AUTO_CLOSE_TIME,
      };
    },
    notificationApiError: (state, action) => {
      const {source = 'Api Error'} = action.payload;
      const useId = typeof source === 'string' ? source.replace(/\s/g, '') : `${Math.ceil(Math.random() * 1000)}`;
      const autoClose = Array.isArray(source) && source.length > 1 ? 3500 + source.length * 1500 : DEFAULT_NOTIFICATION_AUTO_CLOSE_TIME;
      const message = Array.isArray(source) ? source.join(', ') : source;

      state.notification = {
        ...state.notification,
        type: 'error',
        message,
        level: 'page',
        id: useId,
        autoClose,
      };
    },
  },
});

/*
*******************************************************
  EXPORTS
*******************************************************
*/
const getAppSelector = (state: RootState) => state?.application;
export const selectors = {
  applicationSelector: createSelector(getAppSelector, application => application),
  loadingSelector: createSelector(getAppSelector, application => application?.loading || false),
  notificationSelector: createSelector(getAppSelector, application => application.notification),
};

export const applicationDuck = {
  actions,
  reducer,
  selectors,
};
