import React, {ReactNode, useMemo} from 'react';
import cn from 'classnames';
import {Icon} from 'ht-styleguide';
import {noop} from '@utils/event';

interface IStatusListItemProps {
  /**
   * Override action icon class name (right side)
   */
  actionIconClassNameOverride?: string;
  /**
   * Override action icon name (right side). Pass `false` to hide icon.
   */
  actionIconNameOverride?: false | string;
  /**
   * Action to perform on clicking item
   */
  actionOnClick?: () => void;
  /**
   * Custom class name
   */
  className?: string;
  /**
   * Status of the list item with predefined icon and color
   */
  status?: 'completed' | 'flagged' | null;
  /**
   * Override status icon class name (left side)
   */
  statusIconClassNameOverride?: string;
  /**
   * Override status icon name (left side). Pass `false` to hide icon.
   */
  statusIconNameOverride?: false | string;
  /**
   * Render a component below the title
   */
  Subtitle?: ReactNode;
  /**
   * List item title
   */
  title: string;
}

const getStatusIconProps = (status: IStatusListItemProps['status']) => {
  switch (status) {
    case 'completed':
      return {
        iconName: 'Check-Round-Fill',
        iconClassName: 'completed_icon',
      };
    case 'flagged':
      return {
        iconName: 'flag-filled',
        iconClassName: 'flagged_icon',
      };
    default:
      return {
        iconName: 'circle',
        iconClassName: 'n300',
      };
  }
};

const StatusListItem = ({
  actionIconClassNameOverride,
  actionIconNameOverride,
  actionOnClick = noop,
  className,
  status,
  statusIconClassNameOverride,
  statusIconNameOverride,
  Subtitle,
  title,
}: IStatusListItemProps) => {
  const containerClass = cn('paddingY-small flex justifyContentSpaceBetween alignItemsCenter', className);
  const {iconName: baseStatusIconName, iconClassName: baseIconStatusClassName} = useMemo(() => getStatusIconProps(status), [status]);
  const statusIcon = statusIconNameOverride === false ? null : statusIconClassNameOverride || baseStatusIconName;
  const statusIconClass = cn('marginRight-small', 'icon-large', statusIconClassNameOverride || baseIconStatusClassName);
  const actionIcon = actionIconNameOverride === false ? null : actionIconClassNameOverride || 'chevron-right';
  const actionIconClass = cn('n700 marginLeft-small', 'icon-large', actionIconClassNameOverride);

  return (
    <div className={containerClass} onClick={actionOnClick}>
      <div className="flex alignItemsCenter">
        {statusIcon && <Icon name={statusIcon} className={statusIconClass} />}
        <div>
          <p className="h6">{title}</p>
          {Subtitle}
        </div>
      </div>
      {actionIcon && <Icon name={actionIcon} className={actionIconClass} />}
    </div>
  );
};

export default StatusListItem;
