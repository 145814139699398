import React, {useEffect} from 'react';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {useAppDispatch, useAppSelector} from '@store/store';
/* Paths */
import {addSkuToJobPath, jobPath} from '@constants/constants.paths';
/* Ducks */
import {projectsDuck} from '@features/Projects/Projects.ducks';
import {skusDuck} from '@features/Skus/Skus.ducks';
/* Components */
import GridMdu from '@components/UI/GridMdu';
import HeaderBackButtonAndText from '@components/UI/Headers/Header.BackButtonAndText';
import {CardSku} from '@components/UI/Card';
import {MduTechApiSku} from '@features/Skus/skus.types';
import {Icon} from 'ht-styleguide';

const ListPartnerSkus = () => {
  /* Router */
  const router = useRouter();
  const {
    push,
    query: {jid, pid},
  } = router;

  /* Hooks */
  const dispatch = useAppDispatch();
  const project = useAppSelector(projectsDuck.selectors.getCurrentProject(pid as string));
  const skusAvailableByPartnerId = useAppSelector(skusDuck.selectors.getSkusAvailableEntities);
  const skusAvailablePartnerIds = useAppSelector(skusDuck.selectors.getSkusAvailablePartnerIds);

  /* Data */
  const partnerSkus: MduTechApiSku[] = skusAvailableByPartnerId[project?.partner?.id]?.skus || [];

  /* Conditionals */
  const isAnySkuAvailableToAdd = Boolean(partnerSkus.length);
  const isPartnerSkuDataFetched = skusAvailablePartnerIds.includes(project?.partner?.id);
  /* Header Actions */
  const onBackArrowClick = () => push(jobPath(pid, jid));

  useEffect(() => {
    // In order to display the proper partner name and also fetch the proper partner skus we need
    // project.partner. Fetch if it isn't in the store
    if (router.isReady) {
      const getProjectDetails = async () => {
        await dispatch(projectsDuck.actions.getProjectById());
      };
      const fetchPartnerSkus = async (partnerId: number) => {
        await dispatch(skusDuck.actions.getPartnerSkus({partnerId, search: ''}));
      };
      if (!project.partner) getProjectDetails();

      if (project.partner && !isPartnerSkuDataFetched) {
        fetchPartnerSkus(project.partner.id);
      }
    }
  }, [router.isReady, project.partner, dispatch, isPartnerSkuDataFetched]);

  if (!project.partner) return null;
  return (
    <>
      <HeaderBackButtonAndText headerText={`Add a ${project.partner.name} service`} onBackArrowClick={onBackArrowClick} />
      <GridMdu.Fluid>
        {isAnySkuAvailableToAdd ? (
          <ul>
            {partnerSkus.map(sku => (
              <li key={sku.id}>
                <div className="flex justifyContentSpaceBetween alignItemsCenter">
                  <Icon name="Lock" className="n300 icon-medium marginRight-tiny1" />
                  <Link href={addSkuToJobPath(pid, jid, sku.id)} className="fullwidth n900 p1">
                    <CardSku name={sku.name} />
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div />
        )}
      </GridMdu.Fluid>
    </>
  );
};

export default ListPartnerSkus;
