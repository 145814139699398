import {useMemo} from 'react';
import {isProduction} from '@utils/env';
import {ProjectFull} from '../projects.types';

export const DEV_CHECKLIST_FEATURE_ENABLED_DATE = '2025-02-10';
export const BUFFER_DAYS = 14;

/**
 * Date when checklist feature was enabled
 */
// TODO: Update production's date
const CHECKLIST_FEATURE_ENABLED_DATE = isProduction() ? '2025-04-15' : DEV_CHECKLIST_FEATURE_ENABLED_DATE;

/**
 * Config hook to determine if checklist flow should be enabled.
 */
const useServiceChecklistEnabled = ({project}: {project: ProjectFull | null}) => {
  const isChecklistEnabled = useMemo(() => {
    if (!project || !project.start_date) {
      return null;
    }

    const checklistEnabledDateTwoWeeksLater = new Date(CHECKLIST_FEATURE_ENABLED_DATE);
    // Use 14 day buffer
    checklistEnabledDateTwoWeeksLater.setDate(checklistEnabledDateTwoWeeksLater.getDate() + BUFFER_DAYS);

    // If the project's start date is two weeks after the checklist enabled date, enable the checklist feature
    const isEnabled = new Date(project.start_date) >= checklistEnabledDateTwoWeeksLater;

    return isEnabled;
  }, [project]);

  return isChecklistEnabled;
};

export default useServiceChecklistEnabled;
