import React from 'react';
import cn from 'classnames';
import {noop} from '@utils/event';
import {Children} from '@features/Application/application.types';
import styles from './tab.module.scss';

type TContainerProps = {
  /**
   * Array of <Tab /> components
   */
  children: Children;
  /**
   * If true, each tab's width will be adjusted to fit its content
   */
  contentFitTabLayout?: boolean;
};

const Container = ({children, contentFitTabLayout = false}: TContainerProps) => {
  const classes = cn('flex', styles.headerContainer, {
    [styles.contentFitTabLayout]: contentFitTabLayout,
  });

  return <div className={classes}>{children}</div>;
};

type TabProps = {
  text: string;
  isActive: boolean;
  onClick: () => void;
};

const Tab = ({text, isActive, onClick = noop}: TabProps) => {
  const tabStyles = cn(styles.headerTab, 'plainButton', {
    [styles.active]: isActive,
  });
  return (
    <button type="button" onClick={onClick} className={cn(tabStyles)}>
      <p className="p1">{text}</p>
    </button>
  );
};

const HeaderTabs = {Container, Tab};

export default HeaderTabs;
