import {AnyAction, createAsyncThunk, createSelector, createSlice} from '@reduxjs/toolkit';
import {applicationDuck} from '@features/Application/Application.ducks';
import router from 'next/router';
import * as TS from '@features/Jobs/jobs.types';
import * as ServicesTS from '@features/Services/services.types';
import {ErrorResponse} from '@features/Application/application.types';
import jobAPIs from '@features/Jobs/jobs.api';
import {RootState} from '@store/store';
import {projectsDuck} from '@features/Projects';
import {statusFilterLength} from '@features/Jobs/jobs.utils';
import {htToast} from 'ht-styleguide';
import get from 'lodash/get';
import {asyncActions as servicesActions, servicesDuck} from '@features/Services/Services.ducks';
import {skusDuck} from '@features/Skus';
import {isServiceReadyToSubmit} from '@features/Services/services.utils';
import {formatSiteDataQA} from '@features/Services/Services.hooks';
import {formatPreQuestionAnswersRequest, formatSiteDataQuestionsRequest, isQaFormErrorFree} from '@features/Skus/skus.utils';
import {QuestionsAPIByQuestion} from '@features/Skus/skus.types';

/*
 ************ UTILS

/**
 * * Reducer will handle the logic of the params.
 * @param {JobsState} state
 * @param {boolean} fresh - determines a non-paginated state. Happens on all "action.search" call
 * @returns {SearchParams}
 */
const normalizeSearchParams = (state: TS.JobsState, fresh: boolean = false) => {
  let params = {} as TS.SearchParams;

  if (state.searchTerm) {
    // if keyword searches need pagination, add it later
    params.search = state.searchTerm;

    return params;
  }
  if (state.flagged) {
    params.only_flagged = state.flagged;
  }

  /* Submit search filters */
  /* eslint-disable-next-line no-return-assign */
  const filtersLength = statusFilterLength(state.searchFilter);
  if (filtersLength > 0) {
    params = {...params, ...state.searchFilter};
  }

  params.page = (fresh ? 0 : state.units[TS.SearchTypes.status].pagination?.current_page || 0) + 1;
  params.per_page = state.per_page;

  return params;
};

/*
 ************ ACTIONS
 */
export const asyncActions = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  search: createAsyncThunk<TS.UnitResponsePayload, void, {rejectValue: ErrorResponse; getState: RootState}>('job/search', async (_, {rejectWithValue, dispatch, getState}) => {
    dispatch(applicationDuck.actions.setLoading(true));

    const {jobs} = getState() as RootState;
    const params = normalizeSearchParams(jobs, true);
    const {pid} = router.query;
    const jobResponse = await jobAPIs.search({project_id: pid as string}, params);
    dispatch(applicationDuck.actions.setLoading(false));

    if (jobResponse.err) {
      return rejectWithValue(jobResponse as ErrorResponse);
    }

    return jobResponse;
  }),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  paginationSearch: createAsyncThunk<TS.UnitResponsePayload, void, {rejectValue: ErrorResponse; getState: RootState}>('job/paginationsearch', async (_, {rejectWithValue, dispatch, getState}) => {
    const {pid} = router.query;
    if (!pid) return {data: {units: [], pagination: {}, status: 'success'}};

    dispatch(applicationDuck.actions.setLoading(true));

    const {jobs} = getState() as RootState;
    const params = normalizeSearchParams(jobs);
    const projectResponse = await jobAPIs.search({project_id: pid as string}, params);

    dispatch(applicationDuck.actions.setLoading(false));

    if (projectResponse.err) {
      return rejectWithValue(projectResponse as ErrorResponse);
    }

    return projectResponse;
  }),
  searchCount: createAsyncThunk<TS.CountPayloadResponse, TS.StatusesJob[] | null, {rejectValue: ErrorResponse; getState: RootState}>(
    'job/searchCount',
    async (filters, {rejectWithValue, getState, dispatch}) => {
      dispatch(applicationDuck.actions.setLoading(true));
      const {jobs} = getState() as RootState;

      // For transient local uses, allow for override of passed filters.
      const state = filters ? {...jobs, searchFilter: filters} : jobs;
      const params = normalizeSearchParams(state, true);
      const {pid} = router.query;
      const projectResponse = await jobAPIs.searchCount({project_id: pid as string}, params);

      dispatch(applicationDuck.actions.setLoading(false));

      if (projectResponse.err) {
        return rejectWithValue(projectResponse as ErrorResponse);
      }

      return projectResponse;
    }
  ),
  createJob: createAsyncThunk<TS.UnitWithServicesResponse, TS.CrudJobParams, {rejectValue: ErrorResponse; rejectedMeta: TS.DisplayErrorType; getState: RootState}>(
    'job/createJob',
    async (createJobParams, {rejectWithValue, dispatch}) => {
      dispatch(applicationDuck.actions.setLoading(true));

      const {pid} = router.query;
      const jobResponse = await jobAPIs.createJob({project_id: pid as string}, {unit: createJobParams});

      dispatch(applicationDuck.actions.setLoading(false));

      if (jobResponse.err) {
        return rejectWithValue(jobResponse as ErrorResponse, {displayErrorType: TS.DisplayErrorEnum.inline});
      }

      return jobResponse;
    }
  ),
  startJob: createAsyncThunk<TS.UnitWithServicesResponse, TS.CrudJobParams, {rejectValue: ErrorResponse; rejectedMeta: TS.DisplayErrorType; getState: RootState}>(
    'job/startJob',
    async (createJobParams, {rejectWithValue, dispatch}) => {
      dispatch(applicationDuck.actions.setLoading(true));

      const {pid} = router.query;
      const jobResponse = await jobAPIs.startJob({project_id: pid as string}, {unit: createJobParams});

      dispatch(applicationDuck.actions.setLoading(false));

      if (jobResponse.err) {
        return rejectWithValue(jobResponse as ErrorResponse, {displayErrorType: TS.DisplayErrorEnum.inline});
      }

      return jobResponse;
    }
  ),
  updateJob: createAsyncThunk<TS.UnitWithServicesResponse, TS.CrudJobParams, {rejectValue: ErrorResponse; rejectedMeta: TS.DisplayErrorType; getState: RootState}>(
    'job/updateJob',
    async (createJobParams, {rejectWithValue, dispatch}) => {
      dispatch(applicationDuck.actions.setLoading(true));

      const {pid, jid} = router.query;
      const jobResponse = await jobAPIs.updateJob({project_id: pid as string, unit_id: jid as string}, {unit: createJobParams});

      dispatch(applicationDuck.actions.setLoading(false));

      if (jobResponse.err) {
        return rejectWithValue(jobResponse as ErrorResponse, {displayErrorType: TS.DisplayErrorEnum.inline});
      }

      return jobResponse;
    }
  ),
  getJobDetails: createAsyncThunk<TS.UnitWithServicesResponse, TS.JobDetailsParams, {rejectValue: ErrorResponse; getState: RootState}>(
    'job/getJobDetails',
    async (params, {rejectWithValue, dispatch}) => {
      dispatch(applicationDuck.actions.setLoading(true));

      const {pid, jid} = router.query;
      const jobResponse = await jobAPIs.getJobDetails({project_id: pid as string, unit_id: (params?.id || jid) as string});

      dispatch(applicationDuck.actions.setLoading(false));

      if (jobResponse.err) {
        return rejectWithValue(jobResponse as ErrorResponse);
      }

      return jobResponse;
    }
  ),
  cancelJob: createAsyncThunk<TS.UnitWithServicesResponse, any, {rejectValue: ErrorResponse; getState: RootState}>('job/cancelJob', async (params, {rejectWithValue, dispatch}) => {
    dispatch(applicationDuck.actions.setLoading(true));

    const {pid, jid = params.jid} = router.query;
    const jobResponse = await jobAPIs.cancelJob({project_id: pid as string, unit_id: jid as string}, params);

    dispatch(applicationDuck.actions.setLoading(false));

    if (jobResponse.err) {
      return rejectWithValue(jobResponse as ErrorResponse);
    }
    // Refetch project details to update job progress bar
    await dispatch(projectsDuck.actions.getProjectById());

    const unitName = get(jobResponse, 'data.unit.unit_name', `Job ${jid}`);
    htToast(`${unitName} marked cancelled`, {delay: 500});

    return jobResponse;
  }),
  unserviceJob: createAsyncThunk<TS.UnitWithServicesResponse, any, {rejectValue: ErrorResponse; getState: RootState}>('job/unserviceJob', async (params, {rejectWithValue, dispatch}) => {
    dispatch(applicationDuck.actions.setLoading(true));

    const {pid, jid = params.jid} = router.query;
    const jobResponse = await jobAPIs.unserviceJob({project_id: pid as string, unit_id: jid as string}, params);

    dispatch(applicationDuck.actions.setLoading(false));

    if (jobResponse.err) {
      return rejectWithValue(jobResponse as ErrorResponse);
    }
    // Refetch project details to update job progress bar
    await dispatch(projectsDuck.actions.getProjectById());

    const unitName = get(jobResponse, 'data.unit.unit_name', `Job ${jid}`);
    htToast(`${unitName} marked unserviceable`, {delay: 500});

    return jobResponse;
  }),
  reopenJob: createAsyncThunk<TS.UnitWithServicesResponse, any, {rejectValue: ErrorResponse; getState: RootState}>('job/reopenJob', async (params, {rejectWithValue, dispatch}) => {
    dispatch(applicationDuck.actions.setLoading(true));

    const {pid, jid = params.id} = router.query;
    const jobResponse = await jobAPIs.reopenJob({project_id: pid as string, unit_id: jid as string});

    dispatch(applicationDuck.actions.setLoading(false));

    if (jobResponse.err) {
      return rejectWithValue(jobResponse as ErrorResponse);
    }
    // Refetch project details to update job progress bar
    await dispatch(projectsDuck.actions.getProjectById());

    return jobResponse;
  }),
  approveJob: createAsyncThunk<TS.UnitWithServicesResponse, any, {rejectValue: ErrorResponse; getState: RootState}>('job/approveJob', async (params, {rejectWithValue, dispatch}) => {
    dispatch(applicationDuck.actions.setLoading(true));

    const {pid, jid = params.jid} = router.query;
    const jobResponse = await jobAPIs.approveJob({project_id: pid as string, unit_id: jid as string});
    // Refetch project details to update job progress bar
    dispatch(projectsDuck.actions.getProjectById());
    dispatch(applicationDuck.actions.setLoading(false));

    if (jobResponse.err) {
      return rejectWithValue(jobResponse as ErrorResponse);
    }

    const unitName = get(jobResponse, 'data.unit.unit_name', `Job ${jid}`);

    htToast(`${unitName} has been approved`, {delay: 500});

    return jobResponse;
  }),
  getCancelReasons: createAsyncThunk<TS.CancelReasonsResponse, any, {rejectValue: ErrorResponse; getState: RootState}>(
    'job/cancelReasons',
    async (params, {rejectWithValue, dispatch}) => {
      dispatch(applicationDuck.actions.setLoading(true));

      const {pid, jid = params.id} = router.query;
      const reasonsResponse = await jobAPIs.getCancelReasons({project_id: pid as string, unit_id: jid as string});

      dispatch(applicationDuck.actions.setLoading(false));

      if (reasonsResponse.err) {
        return rejectWithValue(reasonsResponse as ErrorResponse);
      }

      return reasonsResponse;
    },
    {
      condition: (_, {getState}) => {
        const {jobs} = getState() as RootState;
        const alreadyFetchedCancelReasons = Boolean(jobs?.cancelReasons);

        return !alreadyFetchedCancelReasons;
      },
    }
  ),
  getJobLogNotes: createAsyncThunk<TS.GetJobLogNotesResponse, any, {rejectValue: ErrorResponse; getState: RootState}>('job/getJobLogNotes', async (params, {rejectWithValue, dispatch}) => {
    dispatch(applicationDuck.actions.setLoading(true));

    const {pid, jid = params.jid} = router.query;
    const jobLogResponse = await jobAPIs.getJobLogNotes({project_id: pid as string, unit_id: jid as string});

    dispatch(applicationDuck.actions.setLoading(false));

    if (jobLogResponse.err) {
      return rejectWithValue(jobLogResponse as ErrorResponse);
    }
    return jobLogResponse;
  }),
  createJobLogNote: createAsyncThunk<TS.CreateJobLogNoteResponse, any, {rejectValue: ErrorResponse; getState: RootState}>('job/createJobLogNote', async (params, {rejectWithValue, dispatch}) => {
    dispatch(applicationDuck.actions.setLoading(true));

    const {pid, jid = params.jid} = router.query;

    const noteParam = {note: {content: params.note}};
    const jobLogResponse = await jobAPIs.createJobLogNote({project_id: pid as string, unit_id: jid as string}, noteParam);

    dispatch(applicationDuck.actions.setLoading(false));

    if (jobLogResponse.err) {
      return rejectWithValue(jobLogResponse as ErrorResponse);
    }
    return jobLogResponse;
  }),
  performServiceFromJobPage: createAsyncThunk<ServicesTS.ServiceDetailsResponse, any, {rejectValue: ErrorResponse; getState: RootState}>(
    'job/performServiceFromJobPage',
    // @ts-expect-error
    async (params, {dispatch, getState}) => {
      dispatch(applicationDuck.actions.setLoading(true));
      // Fetch data for service and sku
      await dispatch(skusDuck.actions.getSkuById({skuId: params.service.sku.id}));
      await dispatch(servicesDuck.actions.getServiceDetails({sid: params.service.id}));
      // Setup service and sku data
      const updatedStateOne = getState() as RootState;
      const rawSkuData = updatedStateOne.skus.questions.entities[params.service.sku.id];
      const {currentService} = updatedStateOne.services;
      await dispatch(skusDuck.actions.setSelectedSku({selectedSku: rawSkuData, isEditMode: true, currentService}));

      // Validate pre_questions - The BE does not validate these, so we must
      const updatedStateTwo = getState() as RootState;
      const {selectedSku} = updatedStateTwo.skus;
      const skuQuestionEntities = updatedStateTwo.skus.questions.entities;
      const preQuestions: QuestionsAPIByQuestion[] = skuQuestionEntities[selectedSku.skuId]?.questions;
      const preQuestionIds = preQuestions.map(q => String(q.id));
      const {payload: formErrors = {} as any} = await dispatch(skusDuck.actions.verifyQAForm());
      const isFormErrorFree = isQaFormErrorFree({errorIds: Object.keys(formErrors), questionIds: preQuestionIds});

      // Validate onsite_questions - The BE does not validate these, so we must
      const skuSiteDataQuestions = updatedStateTwo.skus.questions.entities[selectedSku.skuId]?.siteDataQuestions;
      const formattedSiteDataQA = formatSiteDataQA({skuSiteDataQuestions, answers: selectedSku.siteDataQuestions});
      const isDeviceAndOnsiteReady = isServiceReadyToSubmit({currentService, selectedSku, siteDataQuestions: formattedSiteDataQA});

      // Perform Service
      let performResponse;
      const isReadyToSubmit = isDeviceAndOnsiteReady && isFormErrorFree;

      const {pid, jid} = router.query;
      if (!isReadyToSubmit) {
        performResponse = {error: true, params: {pid, jid, sid: params.service.id}};
      } else {
        const requestPayload = {
          sid: currentService.id,
          service: {
            sku: {
              id: currentService?.sku?.id,
              pre_questions: formatPreQuestionAnswersRequest({rawSkuData, selectedSku}),
              ...formatSiteDataQuestionsRequest({selectedSku, service: currentService}),
            },
          },
        };
        performResponse = await dispatch(servicesDuck.actions.performService(requestPayload));
      }

      dispatch(applicationDuck.actions.setLoading(false));
      return performResponse;
    }
  ),
  deleteJob: createAsyncThunk<any, TS.DeleteJobParams, {rejectValue: ErrorResponse; getState: RootState}>('job/deleteJob', async (params, {rejectWithValue, dispatch, getState}) => {
    dispatch(applicationDuck.actions.setLoading(true));

    const {pid, jid = params.unit_id} = router.query;
    const {jobs} = getState() as RootState;
    const jobResponse = await jobAPIs.deleteJob({project_id: pid as string, unit_id: jid as string});

    dispatch(applicationDuck.actions.setLoading(false));

    if (jobResponse.err) {
      return rejectWithValue(jobResponse as ErrorResponse);
    }

    // display unit name either off of job state or passed in
    const {unit_name = params.unit_name || ''} = jobs?.job ?? {};

    htToast(`Job '${unit_name}' Deleted`, {delay: 500});

    // Refetch project details to update job progress bar
    await dispatch(projectsDuck.actions.getProjectById());
    // Deleting a unit, we'll need to get the new list.
    await dispatch(asyncActions.search());

    return jobResponse;
  }),
};

/*
*******************************************************
  INITIAL STATE
*******************************************************
*/

// Not sure we need a "jobs" State. As we can rolll it INTO projects state
// via the need for search.
export const defaultSearchFilter = {statuses: [], payment_statuses: []};
export const JOBS_INITIAL_STATE: TS.JobsState = {
  job: null,
  cancelReasons: null,
  units: {
    [TS.SearchTypes.keyword]: {
      jobs: [],
      pagination: {},
    },
    [TS.SearchTypes.status]: {
      jobs: [],
      pagination: {},
    },
  },
  count: 0,
  searchType: TS.SearchTypes.status,
  searchFilter: defaultSearchFilter,
  flagged: false,
  searchTerm: '',
  page: 0,
  per_page: 20,
};

export const initialState = JOBS_INITIAL_STATE;

/*
*******************************************************
  SLICE
*******************************************************
*/
const jobsSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    setFlagged: (state, action) => {
      state.flagged = action.payload;
      state.searchType = TS.SearchTypes.status;
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
      state.searchType = TS.SearchTypes.keyword;
    },
    setSearchType: (state, action) => {
      state.searchType = action.payload;

      // IF type is status, keyword can't exist.
      if (action.payload === TS.SearchTypes.status) {
        state.searchTerm = '';
        state.units[TS.SearchTypes.keyword].jobs = [];
        state.units[TS.SearchTypes.keyword].pagination = {};
      }
    },
    setSearchStatus: (state, action) => {
      state.searchFilter = action.payload;
    },
    setSearchFilterStatuses: (state, action) => {
      state.searchFilter = action.payload;
    },
    clearJob: state => {
      state.job = JOBS_INITIAL_STATE.job;
    },
    clearFullSearch: state => ({
      ...JOBS_INITIAL_STATE,
      cancelReasons: state.cancelReasons,
    }),
    flagService: (state, action) => {
      const {jid, data} = action.payload;
      if (state.job?.services) {
        state.job?.services.forEach((service, idx) => {
          if (service.id === data.service.id) {
            state.job!.services[idx] = data.service;
          }
        });

        state.units[state.searchType].jobs.forEach(job => {
          if (job.id === +jid) {
            job.flagged = true;
          }
        });
      }

      return state;
    },
    unflagService: (state, action) => {
      const {jid, data} = action.payload;

      if (state.job?.services) {
        state.job?.services.forEach((service, idx) => {
          if (service.id === data.service.id) {
            state.job!.services[idx] = data.service;
          }
        });

        state.units[state.searchType].jobs.forEach(job => {
          if (+job.id === +jid) {
            job.flagged = false;
          }
        });
      }

      return state;
    },
    removeService: (state, action) => {
      const {jid, sid} = action.payload;
      const services = state.job?.services.filter(service => service.id !== sid);

      if (state.job) {
        state.job.services = services as TS.UnitService[];
      }
      // Update preSearched job & service
      state.units[state.searchType].jobs.forEach(job => {
        if (Number(job.id) === Number(jid)) {
          job.service_count -= 1;

          job.services = job.services?.filter(service => service.id !== sid);
        }
      });
    },
  },
  extraReducers: builder => {
    builder
      .addCase(asyncActions.search.fulfilled, (state, action) => {
        const {data} = action.payload;

        state.units[state.searchType].jobs = data.units;
        state.units[state.searchType].pagination = data.pagination;
      })
      .addCase(asyncActions.paginationSearch.fulfilled, (state, action) => {
        const {data} = action.payload;

        state.units[state.searchType].jobs = state.units[state.searchType].jobs.concat(data.units);
        state.units[state.searchType].pagination = data.pagination;
      })
      .addCase(asyncActions.searchCount.fulfilled, (state, action) => {
        const {data} = action.payload;
        state.count = data.count;
      })
      .addCase(asyncActions.createJob.fulfilled, (state, action) => {
        const {data} = action.payload;

        state.units[TS.SearchTypes.status].jobs = [data.unit].concat(state.units[TS.SearchTypes.status].jobs);
      })
      .addCase(asyncActions.startJob.fulfilled, (state, action) => {
        const {data} = action.payload;
        const updatedJobs = [data.unit].concat(state.units[TS.SearchTypes.status].jobs.filter(job => Number(job.id) !== Number(data.unit.id)));

        state.units[TS.SearchTypes.status].jobs = updatedJobs;
      })
      .addCase(asyncActions.getCancelReasons.fulfilled, (state, action) => {
        const {data} = action.payload;
        state.cancelReasons = data.reasons;
      })
      .addCase(asyncActions.getJobLogNotes.fulfilled, (state, action) => {
        const {data} = action.payload;
        if (state.job) state.job.notes = data.notes;
      })
      .addCase(asyncActions.createJobLogNote.fulfilled, (state, action) => {
        const {
          data: {note},
        } = action.payload;
        if (state.job) state.job.notes?.unshift(note);
      })
      .addCase(servicesActions.revertService.fulfilled, (state, action) => {
        /*
          After a service is reverted let's update the service on the Job level in order to keep service.status in sync.
          Without updating here service.status is stale and there is a brief delay until we fetch the new Service obj
          from BE on page load.
        */
        if (state.job) {
          const service = action.payload.data.service || {id: -1, status: ''};
          state.job.services.forEach(s => {
            if (s.id === service.id && service.status) s.status = service.status;
          });
        }
      })
      /*
        This bundles up the job assignment on the 'selected job/viewing' and in search line item
      */
      .addMatcher(
        (action): action is AnyAction =>
          [
            asyncActions.cancelJob.fulfilled,
            asyncActions.unserviceJob.fulfilled,
            asyncActions.updateJob.fulfilled,
            asyncActions.approveJob.fulfilled,
            asyncActions.getJobDetails.fulfilled,
            asyncActions.reopenJob.fulfilled,
          ].some(actionCreator => actionCreator.match(action)),
        (state, action: AnyAction) => {
          const {
            data: {unit},
          } = action.payload;
          // Iterate thru the proper search display and update in place
          const newState = state.units[state.searchType].jobs.map(job => {
            if (job.id === unit.id) {
              return unit;
            }

            return job;
          });
          // inline update of search return
          state.units[state.searchType].jobs = newState;

          // update selected job
          state.job = unit;
        }
      );
    // .addCase(HYDRATE, (state, action: any) => {})
  },
});

/*
*******************************************************
  SELECTORS & SELECTOR METHODS
*******************************************************
*/
const getJobsState = (state: RootState) => state?.jobs ?? initialState;
/*
*******************************************************
  EXPORTS
*******************************************************
*/
export const selectors = {
  getJobsState: createSelector(getJobsState, jobs => jobs),
  getSearchResults: createSelector(getJobsState, (jobs): {jobs: TS.Unit[]; pagination: TS.Pagination} => jobs.units[jobs.searchType ?? TS.SearchTypes.status]),
  getCount: createSelector(getJobsState, (jobs): number => jobs.count),
  getSearchType: createSelector(getJobsState, (jobs): TS.SearchTypes => jobs.searchType),
  getSearchTerm: createSelector(getJobsState, (jobs): TS.SearchTypes => jobs.searchTerm),
  getFlagged: createSelector(getJobsState, (jobs): TS.SearchTypes => jobs.flagged),
  getSearchFilter: createSelector(getJobsState, (jobs): TS.UnitSearchFilter => jobs.searchFilter),
  getSearchFilterLength: createSelector(getJobsState, jobs => statusFilterLength(jobs.searchFilter)),
  getCancelReasons: createSelector(getJobsState, (jobs): TS.CancelReasons => jobs.cancelReasons),
  getCurrentJob: createSelector(getJobsState, (jobs): TS.UnitWithServices => jobs.job),
};

export const jobsDuck = {
  actions: {...jobsSlice.actions, ...asyncActions},
  reducer: jobsSlice.reducer,
  selectors,
};
