import {useRef, ChangeEvent, MutableRefObject} from 'react';
import cn from 'classnames';
import debounce from 'lodash/debounce';

/* Utils */
import {noop} from '@utils/event';

/* Components && Styles */
import {Icon} from 'ht-styleguide';
import TouchTarget from '@components/UI/TouchTarget';
import SearchInput from '@components/UI/SearchInput';
import Tab from '@components/UI/Tab';
import styles from '@features/Projects/Parts/Header/header.module.scss';

/* Types */
import {SearchTypes, StatusesProjectFilter, PausedStatusTypes} from '@features/Projects/projects.types';

/* Store */
import {useAppDispatch, useAppSelector} from '@store/store';

/* Constants */
import {BASE_PROJECT_SEARCH_FILTERS} from '@features/Projects/projects.constants';

/* Ducks */
import {userDuck} from '@features/User/User.ducks';
import {projectsDuck} from '@features/Projects/Projects.ducks';

type CustomEvent<T> = ChangeEvent<T> & MutableRefObject<T>;
type HeaderListProps = {setLoaded: React.Dispatch<React.SetStateAction<boolean>>};

const MIN_CHARACTER_SEARCH = 1;

const HeaderList = ({setLoaded}: HeaderListProps) => {
  /* Hooks */
  const inputRef = useRef(null);
  const dispatch = useAppDispatch();
  const signOut = () => dispatch(userDuck.actions.signOut());

  const searchFilters = useAppSelector(projectsDuck.selectors.getSearchFilters);
  const searchType = useAppSelector(projectsDuck.selectors.getSearchType);
  const searchFiltersStatuses = searchFilters.statuses || [];

  /* Data */
  const headerTabs = [
    {
      text: 'In Progress',
      onClick: async () => {
        await dispatch(projectsDuck.actions.setSearchFilters(BASE_PROJECT_SEARCH_FILTERS.IN_PROGRESS));
        await dispatch(projectsDuck.actions.getProjectsByType());
      },
      isActive: searchFiltersStatuses.length === 1 && searchFiltersStatuses[0] === StatusesProjectFilter.not_completed,
      key: 1,
    },
    {
      text: 'Paused',
      onClick: async () => {
        await dispatch(projectsDuck.actions.setSearchFilters(BASE_PROJECT_SEARCH_FILTERS.PAUSED));
        await dispatch(projectsDuck.actions.getProjectsByType());
      },
      isActive: searchFilters.paused === PausedStatusTypes.ONLY_PAUSED,
      key: 2,
    },
    {
      text: 'Complete',
      onClick: async () => {
        await dispatch(projectsDuck.actions.setSearchFilters(BASE_PROJECT_SEARCH_FILTERS.COMPLETED));
        await dispatch(projectsDuck.actions.getProjectsByType());
      },
      isActive: searchFiltersStatuses.length === 1 && searchFiltersStatuses[0] === StatusesProjectFilter.completed,
      key: 3,
    },
  ];
  const showHeaderTabs = searchType === SearchTypes.status;

  /* Methods */
  const clearInputValue = (e: CustomEvent<HTMLInputElement>) => {
    if (e && e.current) {
      e.current.value = '';
    }
    if (e && e.target) {
      e.target.value = '';
    }
  };
  const handleInputIconClick = (e: CustomEvent<HTMLInputElement>) => {
    clearInputValue(e);
    dispatch(projectsDuck.actions.setSearchType(SearchTypes.status));
  };
  const handleInputFocus = async (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (searchType !== SearchTypes.keyword) await dispatch(projectsDuck.actions.setSearchType(SearchTypes.keyword));
    // @ts-ignore
    clearInputValue(e);
  };
  const handleSearchInput = debounce(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e?.target?.value;
      await dispatch(projectsDuck.actions.setSearchTerm(value));

      if (value?.length >= MIN_CHARACTER_SEARCH) {
        setLoaded(false);
        await dispatch(projectsDuck.actions.getProjectsByType());
        setLoaded(true);
      }
    },
    1000,
    {trailing: true, leading: false}
  );

  return (
    <header className={styles.container}>
      <div className={cn(styles.list_icon_row, 'marginBottom-medium')}>
        <div className={styles.list_container_left}>
          <TouchTarget onClick={signOut}>
            <Icon name="Logout" />
          </TouchTarget>
        </div>
        <div className={styles.list_container_center}>
          <Icon name="logo-horizontal" />
        </div>
      </div>
      <h3 className="marginBottom-medium">My Projects</h3>
      <SearchInput
        inputRef={inputRef}
        onInputChange={handleSearchInput}
        onInputFocus={handleInputFocus}
        inputIconName={!showHeaderTabs ? 'v2-close-icon' : ''}
        onInputIconClick={!showHeaderTabs ? e => handleInputIconClick(e) : noop}
        inputPlaceholder="Search for a project"
      />
      <div className="marginBottom-small" />
      {showHeaderTabs && (
        <Tab.Container>
          {headerTabs.map(tab => (
            <Tab.Tab {...tab} />
          ))}
        </Tab.Container>
      )}
    </header>
  );
};

export default HeaderList;
