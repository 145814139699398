import {Children} from 'src/features/Application/application.types';

export enum TouchTargetSizes {
  small = 'small',
}

type TouchTargetProps = {
  children: Children;
  onClick: BaseAnyFunction | ((e: React.MouseEvent<Element, MouseEvent>) => void) | undefined;
  size?: TouchTargetSizes | undefined;
  className?: string | undefined;
};
const TouchTarget = ({children, onClick, className, size = TouchTargetSizes.small}: TouchTargetProps) => (
  <div onClick={onClick} className={`${className} touchTarget ${size}`}>
    {children}
    <style jsx>{`
      .touchTarget {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      .touchTarget[class*='small'] {
        height: 40px;
        width: 40px;
      }
    `}</style>
  </div>
);

export default TouchTarget;
