import {QueryClient, QueryClientProvider, HydrationBoundary, MutationCache, QueryCache} from '@tanstack/react-query';
import {logger} from '@utils/logger';
import {isBrowser} from '@utils/env';

/*
 * Log all errors thrown by react-query.
 * We should also add a toast/snackBar here.
 *
 * NOTE: this is a query only. Does not work with mutation
 * NOTE: We have a 6 second cache for errors to prevent spamming KEY'D by page+error
 * */
const errorCache = new Map();

export function queryErrorHandler(error: unknown): void {
  let errorString: string;
  if (error instanceof Error) {
    errorString = error.toString().replace(/^Error:\s*/, '');
  } else {
    try {
      errorString = JSON.stringify(error);
    } catch (jsonError) {
      errorString = 'Unknown error';
    }
  }

  if (typeof errorString !== 'undefined') {
    const currentPath = isBrowser() ? window.location.pathname : '';
    const cacheKey = `${currentPath}:${errorString}`;
    const cached = errorCache.get(cacheKey);

    /* Skip logging if error is cached */
    if (cached && Date.now() < cached.expires) {
      return;
    }

    /* Cache error for 5 seconds */
    errorCache.set(cacheKey, {
      expires: Date.now() + 6000,
    });

    logger('queryErrorHandler')(errorString);
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      gcTime: Infinity,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      notifyOnChangeProps: 'all',
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: queryErrorHandler,
  }),
  mutationCache: new MutationCache({
    onError: queryErrorHandler,
  }),
});

const QUERY_BASE_TIMINGS = {
  staleTime: 600000, // 10 minutes before fresh data becomes stale
  cacheTime: 900000, // 15 minutes before cache is emptied
};

export {QueryClientProvider, queryClient, QueryClient, HydrationBoundary, QUERY_BASE_TIMINGS};
