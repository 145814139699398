import {TGenericCloudinaryResponse} from 'src/components/Cloudinary/cloudinary.types';
/**
 * The types of tasks the worker must perform per service.
 */
export enum TaskTypes {
  /**
   * Simple confirmation that the task was completed
   */
  Confirm = 'confirm',
  /**
   * Upload one or more (depending on setting) photos
   */
  Photo = 'photo',
  /**
   * Upload one video
   */
  Video = 'video',
  /**
   * Dropdown
   */
  SingleSelect = 'single_select',
  /**
   * Checkbox
   */
  MultiSelect = 'multi_select',
  /**
   * Radio with only two options
   */
  Binary = 'binary',
  /**
   * Render a text area in the tech app
   */
  Text = 'text',
  /**
   * Derived from sku's device question if it exists
   */
  Device = 'device',
  /**
   * If the worker needs to report an incident or damage. Will always exist on each service
   * except for MDU orders.
   */
  Incident = 'incident',
}

export enum ReferenceTypes {
  Link = 'link',
  File = 'file',
  FromSkill = 'from_skill',
}

type TTaskOption = {
  id: number;
  created_at: string;
  description: string;
  updated_at: string;
};

export type TOrderTaskAnswerUpdatedBy = {
  /** When the user was created */
  created_at: string;
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  /** "First L." */
  short_name: string;
  /** When the user object was updated at */
  updated_at: string;
  user_type: 'Geek' | 'Admin';
};

export type TReference = {
  id: number;
  created_at: string;
  updated_at: string;
  reference_type: ReferenceTypes;
  /**
   * Used with reference_type 'from_skill'
   */
  skill_guide_id: number | null;
  skill_guide: {
    created_at: string;
    id: number;
    link: string;
    name: string;
    updated_at: string;
  } | null;
  /**
   * Used with reference_type 'link'
   */
  title: string | null;
  /**
   * Used with reference_type 'link' and 'file'
   */
  url: string | null;
  /**
   * Used with reference_type 'file'
   */
  attachments: TGenericCloudinaryResponse[];
};

export type TTaskDeviceData = {
  product_category: {
    created_at: string;
    id: number;
    name: string;
    updated_at: string;
  };
  product_make: {
    created_at: string;
    /**
     * Make is known but did not exist in the list of options when the device
     * question was answered
     */
    custom: boolean;
    id: number;
    /**
     * "I don't know" - The make was unknown when the device question was answered
     */
    idk: boolean;
    name: string;
    updated_at: string;
  };
  product: {
    created_at: string;
    custom: boolean;
    id: number;
    idk: boolean;
    /**
     * Image url of the product
     */
    image_url: string | null;
    name: string;
    updated_at: string;
  };
};

/**
 * Worker's answer to a task
 */
export type TTaskAnswer = {
  id: number;
  /**
   * `incomplete` - when admin marks the task as incomplete
   */
  completed_by_role: 'geek' | 'admin' | 'incomplete';
  created_at: string;
  updated_at: string;
  updated_by: TOrderTaskAnswerUpdatedBy | null;
  updated_by_role: 'geek' | 'admin' | null;
  /**
   * Answer ids for binary, single_select, multi_select task types.
   */
  options: Array<{id: number; created_at?: string; updated_at?: string; description?: string}> | null | undefined;
  /**
   * Text answer for text task type, incident task type, and optional note.
   * Inputs for each action will never appear together for one task.
   */
  response: string | null | undefined;
  /**
   * File attachments for photo/video/incident task types and optional photo.
   * These actions will never appear together for one task.
   */
  attachments: TGenericCloudinaryResponse[] | null | undefined;
  /**
   * Device data for device task type.
   */
  device_response: TTaskDeviceData | null;
  /**
   * If there are validation errors on the BE side, this will populate.
   */
  errors?: {
    // 'options' | 'response' | 'attachments' | 'device_response'
    [key: string]: string[];
  };
};

export type TTask = {
  answer: TTaskAnswer | null;
  created_at: string;
  /**
   * Represents device data for sku's device question. See `task.answer.device_response`
   * for the user's response to the device task. Device data should be synced between
   * sku's device question and the task.
   */
  device?: TTaskDeviceData | null;
  id: number;
  include_other_option: boolean;
  instructions: string | null;
  multiple_photos: boolean;
  options: TTaskOption[];
  reference: TReference | null;
  required_task: boolean;
  show_optional_note: boolean;
  show_optional_photo: boolean;
  complete: boolean;
  task_type: TaskTypes;
  title: string;
  updated_at: string;
};

export type TTaskGroup = {
  created_at: string;
  group_name: string;
  id: number;
  locked: boolean;
  complete: boolean;
  tasks: TTask[];
  updated_at: string;
};

export type TTaskChecklist = {
  grouping: boolean;
  all_tasks_passed: boolean;
  task_groups: TTaskGroup[];
};

export type TTaskUpdatePayload = Pick<TTaskAnswer, 'options' | 'response' | 'attachments'> & {
  device_response: null | {
    /**
     * This can be the product_make.id, -1 for "I don't know", or a string for custom make entered
     * by the user
     */
    product_make_id: number | string;
    /**
     * This can be the product.id, -1 for "I don't know", or a string for custom make entered
     * by the user
     */
    product_id: number | string;
  };
};
