import router from 'next/router';
import {ModalNames} from '@features/Projects/projects.types';

type UpdateRouteByModalRequestOptsParams = {
  query?: Record<string, string | number>;
};

// eslint-disable-next-line import/prefer-default-export
export const updateRouteByModalRequest = (modal: ModalNames, opts: UpdateRouteByModalRequestOptsParams = {}) => {
  const param = router.query[modal];

  // does our param already exist
  if (param) {
    // delete it
    delete router.query[modal];
  }

  router.push(
    {
      pathname: router.route,
      query: {...router.query, [modal]: 'mdl', ...opts.query},
    },
    undefined,
    {shallow: true}
  );
};
